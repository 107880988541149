import React, { useState, useEffect } from 'react';
import ChatBot from 'react-simple-chatbot';
//import '../styles/chatbot.css';
import '../styles/temp.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../providers';
import axios from 'axios';

const FitnessChatbot = () => {
    const [showChat, setShowChat] = useState(false);
    const [userInput, setUserInput] = useState({});
    const auth = useAuth();
    const backendUrl = "https://medharbor-backend.onrender.com/";
    const user_id = auth.user?.id;


    let waterAmount, selectedDate, sleepAmount, stressLevel, caloriesBurnt, selectedMuscleGroup, selectedExercise, waterGoal, caloriesToConsumeGoal, caloriesToBurnGoal, mealcalories, mealname, selectedMealType;

    const toggleChat = () => {
        setShowChat(!showChat);
    };

    const closeChat = () => {
        setShowChat(false);
    };

    const sendToBackend = async (userData, endpoint) => {
        try {
          console.log('sending data to backend');
          console.log('data:', userData);
          console.log('endpoint:', endpoint);
          console.log('backendUrl:', backendUrl);
          const response = await axios.post(`${backendUrl}${endpoint}`, {
            ...userData,
          });
          console.log('Data sent successfully:', response.data);
          setUserInput({}); 
          return response.data; 
        } catch (error) {
          console.error('Error sending data:', error);
          throw error; 
        }
      };
  
      

    const SubmitFitnessData = ({ triggerNextStep }) => {
      const submitData = async () => {
        try {
          console.log('Submitting fitness data:', userInput);

          await axios.post(`${backendUrl}fitness`, {
            ...userInput,
            userId: auth.user.id
          });

          console.log('Fitness data submitted successfully');
          setUserInput({}); 
        } catch (error) {
          console.error("Error submitting fitness data:", error);
        }
      };
    
      useEffect(() => {
        submitData();
      }, []); 
    
      return <div>Submitting your fitness data, please wait...</div>;
    };

    const SubmitMealCaloriesData = ({ triggerNextStep }) => {
      const submitData = async () => {
          try {
            console.log('Submit Meal Data called with date and data', selectedDate, mealcalories, mealname, selectedMealType);
      
            setUserInput(prevState => {
              const formattedDate = new Date(selectedDate).toISOString().split('T')[0] + "T00:00:00.000+00:00";
              const newState = {
                date: formattedDate,
                type: selectedMealType,
                mealName: mealname,
                calories: mealcalories,
                creator: user_id,
              };
              console.log('Updated userInput:', newState);
      
              sendToBackend(newState, "meals"); 
      
              return newState; 
            });
      
          } catch (error) {
            console.error("Error submitting mealCalories data:", error);
          }
        };
      
        useEffect(() => {
          console.log("Inside SubmitCaloriesBurntData component");
          submitData();
        }, []); 
      
        return <div>Submitting your meals data, please wait...</div>;
  };
    
    const SubmitCaloriesBurntData = ({ triggerNextStep }) => {
        const submitData = async () => {
            try {
              console.log('Submit Water Data called with date and data', selectedDate, caloriesBurnt, selectedExercise, selectedMuscleGroup);
        
              setUserInput(prevState => {
                const newState = {
                  date: selectedDate,
                  muscleGroup: selectedMuscleGroup,
                  exercise: selectedExercise,
                  calories: caloriesBurnt,
                  creator: user_id,
                };
                console.log('Updated userInput:', newState);
        
                sendToBackend(newState, "workout"); 
        
                return newState; 
              });
        
            } catch (error) {
              console.error("Error submitting caloriesBurnt data:", error);
            }
          };
        
          useEffect(() => {
            console.log("Inside SubmitCaloriesBurntData component");
            submitData();
          }, []); 
        
          return <div>Submitting your calories burnt data, please wait...</div>;
    };

    const SubmitWaterData = ({ triggerNextStep }) => {
        const submitData = async () => {
          try {
            console.log('Submit Water Data called with date and amount', selectedDate, waterAmount);
      
            setUserInput(prevState => {
              const newState = {
                date: selectedDate,
                amount: waterAmount,
                creator: user_id,
              };
              console.log('Updated userInput:', newState);
      
              sendToBackend(newState, "water"); 
      
              return newState; 
            });
      
          } catch (error) {
            console.error("Error submitting water data:", error);
          }
        };
      
        useEffect(() => {
          console.log("Inside SubmitWaterData component");
          submitData();
        }, []); 
      
        return <div>Submitting your water data, please wait...</div>;
      };
      

    const SubmitSleepData = ({ triggerNextStep }) => {
        const submitData = async () => {
            try {
              console.log('Submit sleep Data called with date and amount', selectedDate, sleepAmount);
        
              setUserInput(prevState => {
                const newState = {
                  date: selectedDate,
                  amount: sleepAmount,
                  creator: user_id,
                };
                console.log('Updated userInput:', newState);
        
                sendToBackend(newState, "sleep"); 
        
                return newState; 
              });
        
            } catch (error) {
              console.error("Error submitting sleep data:", error);
            }
          };
        
          useEffect(() => {
            console.log("Inside SubmitSleepData component");
            submitData();
          }, []); 
        
          return <div>Submitting your sleep data, please wait...</div>;
    };

    const SubmitStressData = ({ triggerNextStep }) => {
        const submitData = async () => {
            try {
              console.log('Submit stress Data called with date and amount', selectedDate, stressLevel);
        
              setUserInput(prevState => {
                const newState = {
                  date: selectedDate,
                  amount: stressLevel,
                  creator: user_id,
                };
                console.log('Updated userInput:', newState);
        
                sendToBackend(newState, "stress"); 
        
                return newState; 
              });
        
            } catch (error) {
              console.error("Error submitting sleep data:", error);
            }
          };
        
          useEffect(() => {
            console.log("Inside SubmitStressData component");
            submitData();
          }, []); 
        
          return <div>Submitting your stress data, please wait...</div>;
    };

    const SubmitGoalsData =  ({ triggerNextStep })  => {
        const submitData = async () => {
            try {
              console.log('Submit goals Data called with date and amount', selectedDate, waterGoal, caloriesToConsumeGoal, caloriesToBurnGoal);
        
              setUserInput(prevState => {
                const newState = {
                  date: selectedDate,
                  water: waterGoal,
                  calories_consume: caloriesToConsumeGoal,
                  calories_burn: caloriesToBurnGoal,
                  creator: user_id,
                };
                console.log('Updated userInput:', newState);
        
                sendToBackend(newState, "goals"); 
        
                return newState; 
              });
        
            } catch (error) {
              console.error("Error submitting goals data:", error);
            }
          };
        
          useEffect(() => {
            console.log("Inside SubmitGoalsData component");
            submitData();
          }, []); 
        
          return <div>Submitting your goals data, please wait...</div>;
    };


    const steps = [
      {
        id: '1',
        message: 'Hi, welcome to MedHarbor. What would you like to log today?',
        trigger: 'activityChoice'
      },
      {
        id: 'activityChoice',
        options: [
          { value: 'workout', label: 'Workout', trigger: 'workoutDate' },
          { value: 'water', label: 'Water Intake', trigger: 'waterDate' },
          { value: 'sleep', label: 'Sleep', trigger: 'sleepDate' },
          { value: 'stress', label: 'Stress Level', trigger: 'stressDate' },
          { value: 'goal', label: 'Set Goal', trigger: 'goalDate' },
          { value: 'meal', label: 'Add Meal', trigger: 'mealDate' }
        ]
      },
      // Workout
    {
        id: 'workoutDate',
        message: 'Please enter the date for your workout (in MM/DD/YYYY format):',
        trigger: 'workoutDateInput'
    },
    {
        id: 'workoutDateInput',
        user: true,
        validator: (value) => {
        const datePattern = /^\d{2}\/\d{2}\/\d{4}$/; // MM/DD/YYYY format
        if (!datePattern.test(value)) {
            return 'Please enter a valid date in MM/DD/YYYY format.';
        }
        
        // Store the date in userInput or as a variable
        selectedDate = value;
        return true;
        },
        trigger: 'workoutDetails'
    },
      
      {
        id: 'workoutDetails',
        message: 'Please select muscle group:',
        trigger: 'workoutMuscle'
      },
      {
        id: 'workoutMuscle',
        options: [
            { value: 'chest', label: 'Chest', trigger: () => { selectedMuscleGroup = 'Chest'; return 'muscleChest'; }},
            { value: 'back', label: 'Back', trigger: () => { selectedMuscleGroup = 'Back'; return 'muscleBack'; }},
            { value: 'legs', label: 'Legs', trigger: () => { selectedMuscleGroup = 'Legs'; return 'muscleLegs'; }},
            { value: 'biceps', label: 'Biceps', trigger: () => { selectedMuscleGroup = 'Biceps'; return 'muscleBiceps'; }},
            { value: 'triceps', label: 'Triceps', trigger: () => { selectedMuscleGroup = 'Triceps'; return 'muscleTriceps'; }},
            { value: 'cardio', label: 'Cardio', trigger: () => { selectedMuscleGroup = 'Cardio'; return 'muscleCardio'; }},
            { value: 'shoulders', label: 'Shoulders', trigger: () => { selectedMuscleGroup = 'Shoulders'; return 'muscleShoulders'; }}
        ]
      },
      // chest
      {
        id: 'muscleChest',
        message: 'Please select exercise:',
        trigger: 'exerciseChest'
      },
      {
        id: 'exerciseChest',
        options: [
          
            { value: 'pushups', label: 'Push-Ups', trigger: () => { selectedExercise = 'Push-Ups'; return 'caloriesBurnt'; }},
            { value: 'benchpress', label: 'Bench Press', trigger: () => { selectedExercise = 'Bench Press'; return 'caloriesBurnt'; }},
            { value: 'flyes', label: 'Flyes', trigger: () => { selectedExercise = 'Flyes'; return 'caloriesBurnt'; }},
            { value: 'other', label: 'Other', trigger: () => { selectedExercise = 'Other'; return 'caloriesBurnt'; }}
          
        ]
      },
      // back
      {
        id: 'muscleBack',
        message: 'Please select exercise:',
        trigger: 'exerciseBack'
      },
      {
        id: 'exerciseBack',
        options: [
          
            { value: 'pullups', label: 'Pull-Ups', trigger: () => { selectedExercise = 'Pull-Ups'; return 'caloriesBurnt'; }},
            { value: 'rows', label: 'Rows', trigger: () => { selectedExercise = 'Rows'; return 'caloriesBurnt'; }},
            { value: 'deadlifts', label: 'Deadlifts', trigger: () => { selectedExercise = 'Deadlifts'; return 'caloriesBurnt'; }},
            { value: 'other', label: 'Other', trigger: () => { selectedExercise = 'Other'; return 'caloriesBurnt'; }}
        ]
      },
      // legs
      {
        id: 'muscleLegs',
        message: 'Please select exercise:',
        trigger: 'exerciseLegs'
      },
      {
        id: 'exerciseLegs',
        options: [
            { value: 'squats', label: 'Squats', trigger: () => { selectedExercise = 'Squats'; return 'caloriesBurnt'; }},
            { value: 'lunges', label: 'Lunges', trigger: () => { selectedExercise = 'Lunges'; return 'caloriesBurnt'; }},
            { value: 'legpress', label: 'Leg Press', trigger: () => { selectedExercise = 'Leg Press'; return 'caloriesBurnt'; }},
            { value: 'other', label: 'Other', trigger: () => { selectedExercise = 'Other'; return 'caloriesBurnt'; }}
        ]
      },
      //biceps
      {
        id: 'muscleBiceps',
        message: 'Please select exercise:',
        trigger: 'exerciseBiceps'
      },
      {
        id: 'exerciseBiceps',
        options: [
          
            { value: 'pushups', label: 'Push-Ups', trigger: () => { selectedExercise = 'Push-Ups'; return 'caloriesBurnt'; }},
            { value: 'pullups', label: 'Pull-Ups', trigger: () => { selectedExercise = 'Pull-Ups'; return 'caloriesBurnt'; }},
            { value: 'bicepcurls', label: 'Bicep Curls', trigger: () => { selectedExercise = 'Bicep Curls'; return 'caloriesBurnt'; }},
            { value: 'other', label: 'Other', trigger: () => { selectedExercise = 'Other'; return 'caloriesBurnt'; }}
        ]
      },
      // triceps
      {
        id: 'muscleTriceps',
        message: 'Please select exercise:',
        trigger: 'exerciseTriceps'
      },
      {
        id: 'exerciseTriceps',
        options: [
          
            { value: 'kickbacks', label: 'Tricep Kickbacks', trigger: () => { selectedExercise = 'Tricep Kickbacks'; return 'caloriesBurnt'; }},
            { value: 'pushups', label: 'Push-Ups', trigger: () => { selectedExercise = 'Push-Ups'; return 'caloriesBurnt'; }},
            { value: 'other', label: 'Other', trigger: () => { selectedExercise = 'Other'; return 'caloriesBurnt'; }}

        ]
      },
      // cardio
      {
        id: 'muscleCardio',
        message: 'Please select exercise:',
        trigger: 'exerciseCardio'
      },
      {
        id: 'exerciseCardio',
        options: [
          
            { value: 'squats', label: 'Squats', trigger: () => { selectedExercise = 'Squats'; return 'caloriesBurnt'; }},
            { value: 'jumpingjacks', label: 'Jumping Jacks', trigger: () => { selectedExercise = 'Jumping Jacks'; return 'caloriesBurnt'; }},
            { value: 'burpees', label: 'Burpees', trigger: () => { selectedExercise = 'Burpees'; return 'caloriesBurnt'; }},
            { value: 'other', label: 'Other', trigger: () => { selectedExercise = 'Other'; return 'caloriesBurnt'; }}
        ]
      },
      // shoulders
      {
        id: 'muscleShoulders',
        message: 'Please select exercise:',
        trigger: 'exerciseShoulders'
      },
      {
        id: 'exerciseShoulders',
        options: [
            { value: 'frontraise', label: 'Front Raise', trigger: () => { selectedExercise = 'Front Raise'; return 'caloriesBurnt'; }},
            { value: 'lateralraise', label: 'Lateral Raise', trigger: () => { selectedExercise = 'Lateral Raise'; return 'caloriesBurnt'; }},
            { value: 'reversefly', label: 'Reverse Fly', trigger: () => { selectedExercise = 'Reverse Fly'; return 'caloriesBurnt'; }},
            { value: 'other', label: 'Other', trigger: () => { selectedExercise = 'Other'; return 'caloriesBurnt'; }}
          ]
      },
      // calories burnt
      {
        id: 'caloriesBurnt',
        message: 'Please enter calories burnt:',
        trigger: 'caloriesBurntAmount'
      },
      {
        id: 'caloriesBurntAmount',
        user: true,
        trigger: 'submit-caloriesburnt-data',
        validator: (value) => {
          if (!value) {
            return 'Please enter some details.';
          }
          caloriesBurnt = value;
          return true;
        },
        // trigger: (input) => {
        //   setUserInput((prev) => ({ ...prev, workoutDetails: input }));
        //   return 'submit';
        // }
      },
      {
        id: 'submit-caloriesburnt-data',
        component: <SubmitCaloriesBurntData />,
        asMessage: true,
        trigger: 'confirm',
      },
    
      {
        id: 'confirm',
        message: 'Thank you. Your information has been recorded. Would you like to add anything else?',
        trigger: 'confirm-options',  
    },

     
      {
        id: 'confirm-options',
        options: [
          { value: 'yes', label: 'Yes', trigger: 'activityChoice' },
          { value: 'no', label: 'No', trigger: 'end-chat' },
        ],
      },
      {
        id: 'end-chat',
        message: 'Thank you for using MedHarbor. Have a great day!',
        end: true,
      },
      // Water
      {
        id: 'waterDate',
        message: 'Please enter the date for today’s water intake (in MM/DD/YYYY format):',
        trigger: 'waterDateInput'
      },
      {
        id: 'waterDateInput',
        user: true,
        validator: (value) => {
          const datePattern = /^\d{2}\/\d{2}\/\d{4}$/; 
          if (!datePattern.test(value)) {
            return 'Please enter a valid date in MM/DD/YYYY format.';
          }
      
          selectedDate = value;
          return true;
        },
        trigger: 'waterDetails'
      },
      {
        id: 'waterDetails',
        message: 'How much water did you drink (in liters)?',
        trigger: 'waterInput'
      },
      {
        id: 'waterInput',
        user: true,
        trigger: 'submit-water-data',
        validator: (value) => {
          if (isNaN(value) || value <= 0 || value > 6) {
            return 'Please enter a value between 0 and 6 liters.';
          }
          waterAmount = value;
          return true;
        }
      },
      {
        id: 'submit-water-data',
        component: <SubmitWaterData />,
        asMessage: true,
        trigger: 'confirm',
      },
      
      
    
      // Sleep
      {
        id: 'sleepDate',
        message: 'Please enter the date for today’s sleep data (in MM/DD/YYYY format):',
        trigger: 'sleepDateInput'
      },
      {
        id: 'sleepDateInput',
        user: true,
        validator: (value) => {
          const datePattern = /^\d{2}\/\d{2}\/\d{4}$/; 
          if (!datePattern.test(value)) {
            return 'Please enter a valid date in MM/DD/YYYY format.';
          }
          
          selectedDate = value;          
          return true;
        },
        trigger: 'sleepDetails'
      },
      {
        id: 'sleepDetails',
        message: 'How much did you sleep (in hours)?',
        trigger: 'sleepInput'
      },
      {
        id: 'sleepInput',
        user: true,
        trigger: 'submit-sleep-data',
        validator: (value) => {
          if (isNaN(value) || value <= 0 || value > 12) {
            return 'Please enter a value betweeen 0 and 12 hours.';
          }
          sleepAmount = value;
          return true;
        },
        // trigger: (input) => {
        //   setUserInput((prev) => ({ ...prev, waterIntake: input }));
        //   return 'submit';
        // }
      },
      {
        id: 'submit-sleep-data',
        component: <SubmitSleepData />,
        asMessage: true,
        trigger: 'confirm',
      },
      // Stress
      {
        id: 'stressDate',
        message: 'Please enter the date for today’s stress level (in MM/DD/YYYY format):',
        trigger: 'stressDateInput'
      },
      {
        id: 'stressDateInput',
        user: true,
        validator: (value) => {
          const datePattern = /^\d{2}\/\d{2}\/\d{4}$/; // MM/DD/YYYY format
          if (!datePattern.test(value)) {
            return 'Please enter a valid date in MM/DD/YYYY format.';
          }
          
          // Store the date in userInput or as a variable
          selectedDate = value;
          return true;
        },
        trigger: 'stressDetails'
      },
      {
        id: 'stressDetails',
        message: 'What is your stress level today (1-10)?',
        trigger: 'stressInput'
      },
      {
        id: 'stressInput',
        user: true,
        trigger: 'submit-stress-data',
        validator: (value) => {
          if (isNaN(value) || value <= 0 || value > 10) {
            return 'Please enter a value betweeen 0 and 10.';
          }
          stressLevel = value;
          return true;
        },
        // trigger: (input) => {
        //   setUserInput((prev) => ({ ...prev, waterIntake: input }));
        //   return 'submit';
        // }
      },
      {
        id: 'submit-stress-data',
        component: <SubmitStressData />,
        asMessage: true,
        trigger: 'confirm',
      },
      // Goal
      {
        id: 'goalDate',
        message: 'Please enter the date for your workout (in MM/DD/YYYY format):',
        trigger: 'gaolDateInput'
      },
      {
        id: 'gaolDateInput',
        user: true,
        validator: (value) => {
          const datePattern = /^\d{2}\/\d{2}\/\d{4}$/; // MM/DD/YYYY format
          if (!datePattern.test(value)) {
            return 'Please enter a valid date in MM/DD/YYYY format.';
          }
          selectedDate = value;
          return true;
        },
        trigger: 'waterGoal'
      },
      
      // Water Goal input
      {
        id: 'waterGoal',
        message: 'How much water do you aim to drink today (in liters)?',
        trigger: 'waterGoalInput'
      },
      {
        id: 'waterGoalInput',
        user: true,
        validator: (value) => {
          if (isNaN(value) || value <= 0) {
            return 'Please enter a valid amount in liters.';
          }
          waterGoal = value;
          return true;
        },
        trigger: 'caloriesToConsume'
      },
      
      // Calories to Consume input
      {
        id: 'caloriesToConsume',
        message: 'How many calories do you plan to consume today?',
        trigger: 'caloriesToConsumeInput'
      },
      {
        id: 'caloriesToConsumeInput',
        user: true,
        validator: (value) => {
          if (isNaN(value) || value <= 0) {
            return 'Please enter a valid calorie amount.';
          }
          caloriesToConsumeGoal = value;
          return true;
        },
        trigger: 'caloriesToBurn'
      },
      
      // Calories to Burn input
      {
        id: 'caloriesToBurn',
        message: 'How many calories do you plan to burn today?',
        trigger: 'caloriesToBurnInput'
      },
      {
        id: 'caloriesToBurnInput',
        user: true,
        validator: (value) => {
          if (isNaN(value) || value <= 0) {
            return 'Please enter a valid calorie amount.';
          }
          caloriesToBurnGoal = value;
          return true;
        },
        trigger: 'submit'
      },
      
      // Submission
      {
        id: 'submit',
        component: <SubmitGoalsData />,
        asMessage: true,
        trigger: 'confirm',
      },

      // Meals

      {
        id: 'mealDate',
        message: 'Please enter the date for your meal (in MM/DD/YYYY format):',
        trigger: 'mealDateInput'
    },
    {
        id: 'mealDateInput',
        user: true,
        validator: (value) => {
        const datePattern = /^\d{2}\/\d{2}\/\d{4}$/; // MM/DD/YYYY format
        if (!datePattern.test(value)) {
            return 'Please enter a valid date in MM/DD/YYYY format.';
        }
        
        // Store the date in userInput or as a variable
        selectedDate = value;
        return true;
        },
        trigger: 'mealDetails'
    },
      
      {
        id: 'mealDetails',
        message: 'Please select meal type:',
        trigger: 'mealType'
      },
      {
        id: 'mealType',
        options: [
            { value: 'breakfast', label: 'Breakfast', trigger: () => { selectedMealType = 'Breakfast'; return 'mealName'; }},
            { value: 'lunch', label: 'Lunch', trigger: () => { selectedMealType = 'Lunch'; return 'mealName'; }},
            { value: 'snack', label: 'Snack', trigger: () => { selectedMealType = 'Snack'; return 'mealName'; }},
            { value: 'dinner', label: 'Dinner', trigger: () => { selectedMealType = 'Dinner'; return 'mealName'; }}
            
        ]
      },
      {
        id: 'mealName',
        message: 'Please enter the meal name:',
        trigger: 'mealNameInput',
      },
      {
        id: 'mealNameInput',
        user: true,
        trigger: 'mealCalories',
        validator: (value) => {
          mealname = value;      
          return true;
        },
      },
      
      {
        id: 'mealCalories',
        message: 'Please enter calories of the meal:',
        trigger: 'mealCaloriesInput'
      },
      {
        id: 'mealCaloriesInput',
        user: true,
        trigger: 'submit-mealcalories-data',
        validator: (value) => {
          if (!value) {
            return 'Please enter some details.';
          }
          mealcalories = value;
          return true;
        },
        // trigger: (input) => {
        //   setUserInput((prev) => ({ ...prev, workoutDetails: input }));
        //   return 'submit';
        // }
      },
      {
        id: 'submit-mealcalories-data',
        component: <SubmitMealCaloriesData />,
        asMessage: true,
        trigger: 'confirm',
      }
    ];

    return (
      <div>
        <div className="chat-icon" onClick={toggleChat}>
          <FontAwesomeIcon icon={faCommentDots} size="2x" />
        </div>

        {showChat && (
          <div className="chatbot-container">
            <ChatBot 
              headerTitle="Fitness Chatbot"
              speechSynthesis={{ enable: false, lang: 'en' }}
              steps={steps} />
          </div>
        )}
      </div>
    );
};

export default FitnessChatbot;