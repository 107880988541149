import 'bootstrap/dist/css/bootstrap.min.css';
import { NL } from '../components';
import React, { useState } from 'react';
import Footer from '../components/Footer';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import meetTheTeam1 from '../Assets/meetTheTeam-1.png';
import meetTheTeam2 from '../Assets/meetTheTeam-2.png';
import meetTheTeam3 from '../Assets/meetTheTeam-3.png';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import '../styles/Newsletter.css';



function App(props) {
  const [email, setEmail] = useState('');
  const [subscriptionSuccess, setSubscriptionSuccess] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState(false);
  const [showMeetTheteam, setShowMeetTheTeam] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/i;
    if (!emailRegex.test(email)) {
      console.error('Invalid email format');
      setSubscriptionError(true);
      setSubscriptionSuccess(false);
      return;
    }

    try {
      const response = await fetch('https://medharbor-backend.onrender.com/newsletters', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        console.log('Subscription successful');
        setSubscriptionSuccess(true);
        setSubscriptionError(false); 
        setEmail(''); 
      } else {
        console.error('Subscription failed');
        setSubscriptionSuccess(false); 
        setSubscriptionError(true);
      }
    } catch (error) {
      console.error('Network error:', error);
      setSubscriptionSuccess(false); 
      setSubscriptionError(true);
    }
  };

  const handleMeetTheTeam = (event)=>{
    console.log('event created', event);
    setShowMeetTheTeam(true);
   }

  return (
    <div className="App">
      <main className="newsletter-container" style={{ backgroundColor: '#FFFFFF', width: "100%" }}>
        <header style={{ backgroundColor: '#082144' }}>
          <div className="container pt-4 pt-xl-3">
            <div className="row pt-5" >
              <div
                className="text-center text-md-start mx-auto"
              >
                <div className="text-white">
                  <p className="fw-bold text-success text-center mb-2" />
                  <h1 className="fw-bold text-center text-white">
                    Welcome to Health Insights
                    <br />
                  </h1>
                  <p className="text-center text-white">- Your Trusted Source for Medical Wisdom</p>
                </div>
                <div className="text-center text-white" style={{ margin: 28, paddingTop: 0, marginTop: 42 }}>
                  <p className="text-center text-center mx-auto col-md-8 col-xl-9 text-white" style={{ textAlign: 'center' }}>
                    Our mission is to provide you with credible, up-to-date
                    medical information, insights and advice to support your
                    health and well-being. With our diverse team of doctors, we
                    aim to demystify complex medical topics, answering your
                    pressing health questions, and share stories of hope and
                    healing.
                  </p>
                  <p className="text-white" style={{ marginTop: "75px" }}>Note: The newsletters provided are not medical advice. Please consult your healthcare provider regarding the information provided below.</p>
                </div>
              </div>
            </div>
          </div>
        </header>
        <hr className="border" />
        <section>
          <NL pageType="newsletter" />
        </section>
       
        
        <section
          className="py-5 d-flex flex-column align-items-center"
          style={{ paddingTop: 0, marginTop: 26 }}
        >
            <div className="container"> 
          {subscriptionSuccess && (
            <div className="alert alert-success" role="alert">
              Success! Thank you for subscribing.
            </div>
          )}
          {subscriptionError && (
            <div className="alert alert-danger" role="alert">
              Sorry! The subscription has failed. Please enter a valid email.
            </div>
          )}
          {!subscriptionSuccess && !subscriptionError && (
            <div
              style={{ backgroundColor: '#F5F7F8' }}
              className=" rounded d-flex flex-column justify-content-between align-items-center flex-lg-row p-4 p-lg-5"
            >
              <div className="text-center text-black text-lg-start py-3 py-lg-1">
                <h2 className="fw-bold mb-2">Subscribe to our Newsletter</h2>
              </div>
              <form
                className="d-flex justify-content-center flex-wrap flex-lg-nowrap"
                onSubmit={handleSubmit}
              >
                <div className="my-2">
                  <input
                    className="border rounded-pill shadow-sm form-control"
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Your Email"
                  />
                </div>
                <div className="my-2">
                  <button className="btn btn-primary shadow ms-2" type="submit">
                    Subscribe
                  </button>
                </div>
              </form>
            </div>
          )}
          </div>
          
        </section>
        <div>
          <hr className="border" />
        </div>
          <Footer meettheTeamClicked={handleMeetTheTeam}></Footer>
      </main>
      <Modal className='meet_the_team_modal'
              show={showMeetTheteam}
              aria-labelledby="example-custom-modal-styling-title"
              style={{ '--bs-modal-width': '1100px', display:'flex !important',  alignItems: 'center',
                justifyContent: 'center',  marginLeft:'40px', marginRight:'auto' }}
              onHide={() => setShowMeetTheTeam(false)}
          >
              <Modal.Header closeButton>
                  <Modal.Title id="example-custom-modal-styling-title">
                      Meet The Team
                  </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <div style={{ textAlign: 'center', padding:'14px' }}>
                      <p>
                          Our team is driven by a commitment to excellence,
                          collaboration, and innovation. We believe in the power
                          of diverse perspectives and are dedicated to creating
                          solutions that make a difference.
                      </p>
                  </div>
                  <Container fluid style={{ padding: '20px' }}>
                      <Row>
                          <Col>
                              <div
                                  style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                  }}
                              >
                                  <img
                                      src={meetTheTeam1}
                                      alt="gallary"
                                      style={{
                                          width: '300px',
                                          height: '300px',
                                      }}
                                  />
                                  <h5 style={{ marginTop: '5px' }}>
                                      Bryan Yavari
                                  </h5>
                                  <p>
                                      Founder, Chief Executive Officer, and
                                      Software Engineer
                                  </p>
                              </div>
                          </Col>
                          <Col>
                              <div
                                  style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                  }}
                              >
                                  <img
                                      src={meetTheTeam2}
                                      alt="gallary"
                                      style={{
                                          width: '300px',
                                          height: '300px',
                                      }}
                                  />
                                  <h5 style={{ marginTop: '5px' }}>
                                      Omkaar Shenoy{' '}
                                  </h5>
                                  <p>
                                      {' '}
                                      Chief Operating Officer and Software
                                      Engineer
                                  </p>
                              </div>
                          </Col>
                          <Col>
                              <div
                                  style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                  }}
                              >
                                  <img
                                      src={meetTheTeam3}
                                      alt="gallary"
                                      style={{
                                          width: '300px',
                                          height: '300px',
                                      }}
                                  />
                                  <h5 style={{ marginTop: '5px' }}>
                                      Roxana Namvari
                                  </h5>
                                  <p>Operations Manager</p>
                              </div>
                          </Col>
                      </Row>
                  </Container>
              </Modal.Body>
          </Modal>
          {/* ChatBot Component */}
    </div>
  );
}

export default App;