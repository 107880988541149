import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Card, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import TimePicker from 'react-time-picker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-time-picker/dist/TimePicker.css';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPen } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../providers';
import LoginPopup from '../components/LoginPopup';
import DeleteModal from '../components/deleteModal';
import '../styles/symptomTracker.css';
import '../styles/customDatePickerWidth.css';
import toast from 'react-hot-toast';
import Col from 'react-bootstrap/Col';
import Slider from '../components/slider';
import Container from 'react-bootstrap/Container';
import ChipInput from '../components/chipsInput';
import SymptomMain from '../components/SymptomMain';
import ChatbotSymptoms from '../components/chatbotSymptoms';
//import { isContext } from 'vm';


const SymptomTracker = () => {
    const API_URLS = 'https://medharbor-backend.onrender.com/symptoms'; // change this to og url - 'https://medharbor-backend.onrender.com/symptoms'
    const auth = useAuth();
    const userId = auth.user?.id;
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [symptoms, setSymptoms] = useState([]);
    const [updateID, setUpdateID] = useState();
    const [render, setRender] = useState(false);
    //const [slidervalue, setSliderValue] = useState(0);
    //const [isSliderVisible, setIsSliderVisible] = useState(false);
    const [newSymptom, setNewSymptom] = useState({
        name: '',
        description: '',
        additional: '',
        date: null,
        time: null,
        painLevel: 0,
        isChronic: false,
    });
    const [newSymptomUpdate, setNewSymptomUpdate] = useState({
        name: '',
        description: '',
        additional: '',
        date: null,
        time: null,
        painLevel: 0,
        isChronic: false,
    });
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});
    const [errorsUpdate, setErrorsUpdate] = useState({});
    const [touchedUpdate, setTouchedUpdate] = useState({});
    const [deleteId, setDeleteId] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [feverSymptoms, setFeverSymptoms] = useState([]);
    const [coldSymptoms, setColdSymptoms] = useState([]);
    const [headacheSymptoms, setHeadAcheSymptoms] = useState([]);
    const [otherSymptoms, setOtherSymptoms] = useState([]);
    //const [painLevel, setPainLevel]=useState(0);

    const styles = {
        width: '100px',
        marginLeft: '10px',
    };

    useEffect(() => {
        if (auth.user?.id) {
            getSymptoms();
        }
    }, [auth.user?.id]);

    useEffect(() => {
        if (showModal) {
            setNewSymptom({
                name: '',
                description: '',
                additional: '',
                date: null,
                time: null,
                painLevel: 0,
                isChronic: false,
            });
            setErrors({});
            setTouched({});
        }

        if (showModal2) {
            setErrorsUpdate({});
            setTouchedUpdate({});
        }
    }, [showModal, showModal2]);

    const openModal = () => {
        setShowModal(true);
    };

    
    
    const getSymptoms = async () => { // change for additional symptoms?
        const userId = auth.user?.id;
        const url = `${API_URLS}/${userId}`;
        const data = await fetch(url);
        const res = await data.json();
        console.log(res);
        res.forEach(el =>{
            if (el.description.includes("symptoms:")) {
                let symptomsPart = el.description.split("symptoms:")[1].trim();
                el.additionalSymptoms = symptomsPart.split(",").map(symptom => symptom.trim());
            } else {
                el.additionalSymptoms = [];
            }
        });
        console.log('response after additional symptoms',res);
        setSymptoms(res);
        const feverSymptomsvar = [];
        const coldSymptomsvar = [];
        const headacheSymptomsvar = [];
        const otherSymptomsvar = [];
        res.forEach((el) => {
            if (el.symptomName.toLowerCase().trim() === 'fever') {
                feverSymptomsvar.push(el);
            } else if (el.symptomName.toLowerCase().trim() === 'cold') {
                coldSymptomsvar.push(el);
            } else if (el.symptomName.toLowerCase().trim() === 'headache') {
                headacheSymptomsvar.push(el);
            } else {
                otherSymptomsvar.push(el);
            }
        });
        setFeverSymptoms(feverSymptomsvar);
        setColdSymptoms(coldSymptomsvar);
        setHeadAcheSymptoms(headacheSymptomsvar);
        setOtherSymptoms(otherSymptomsvar);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const closeModal2 = () => {
        setShowModal2(false);
    };

    const closeDeleteModal = () => {
        setShowDeleteModal(false);
    };

    

    const validateField = (name, value, isUpdate = false) => {
        let fieldErrors = isUpdate ? { ...errorsUpdate } : { ...errors };
        let isTouched = isUpdate ? { ...touchedUpdate } : { ...touched };
        isTouched[name] = true;

        switch (name) {
            case 'name':
                if (!value) {
                    fieldErrors[name] = 'Symptom name is required';
                } else if (!/^[A-Za-z\s]+$/.test(value)) {
                    fieldErrors[name] =
                        'Symptom name must contain only alphabets';
                } else {
                    delete fieldErrors[name];
                }
                break;
            case 'description':
                if (!value) {
                    fieldErrors[name] = 'Description is required';
                } else if (!/^[A-Za-z0-9\s:,]+$/.test(value)) {
                    fieldErrors[name] =
                        'Description must not contain special characters';
                } else {
                    delete fieldErrors[name];
                }
                break;
            case 'date':
                if (!value) {
                    fieldErrors[name] = 'Date is required';
                } else {
                    delete fieldErrors[name];
                }
                break;
            case 'time':
                if (!value) {
                    fieldErrors[name] = 'Time is required';
                } else {
                    delete fieldErrors[name];
                }
                break;
            default:
                break;
        }

        if (isUpdate) {
            setErrorsUpdate(fieldErrors);
            setTouchedUpdate(isTouched);
        } else {
            setErrors(fieldErrors);
            setTouched(isTouched);
        }
    };

    const handleAdditionalChange = (e) => { // check
        const { name, value } = e.target;
        setNewSymptom((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    
    const handleIsChronic = (isChronic)=>{
        setNewSymptom((prevState) => ({
            ...prevState,
            isChronic: isChronic,
        }));
  };
    
    const handlePainLevel = (painLevel)=>{
        setNewSymptom((prevState) => ({
            ...prevState,
            painLevel: painLevel,
        }));
  };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewSymptom((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        validateField(name, value);
    };

    const handleDateChange = (date) => {
        setNewSymptom((prevState) => ({
            ...prevState,
            date: date,
        }));
        validateField('date', date);
    };

    const handleTimeChange = (time) => {
        setNewSymptom((prevState) => ({
            ...prevState,
            time: time,
        }));
        validateField('time', time);
    };

    
    const handleUpdateAdditionalChange = (e) => { // check
        const { name, value } = e.target;
        setNewSymptomUpdate((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    
    const handleUpdateIsChronic = (isChronic) => {
        setNewSymptomUpdate((prevState) => ({
            ...prevState,
            isChronic: isChronic,
        }));
    };

    const handleUpdatePainLevel = (painLevel) => {
        setNewSymptomUpdate((prevState) => ({
            ...prevState,
            painLevel: painLevel,
        }));
    };
    
    const handleUpdateInputChange = (e) => {
        const { name, value } = e.target;
        setNewSymptomUpdate((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        validateField(name, value, true);
    };

    const handleUpdateDateChange = (date) => {
        setNewSymptomUpdate((prevState) => ({
            ...prevState,
            date: date,
        }));
        validateField('date', date, true);
    };

    const handleUpdateTimeChange = (time) => {
        setNewSymptomUpdate((prevState) => ({
            ...prevState,
            time: time,
        }));
        validateField('time', time, true);
    };

    const updateSymptom = () => {
        if (
            !Object.keys(errorsUpdate).length &&
            newSymptomUpdate.name &&
            newSymptomUpdate.description &&
            newSymptomUpdate.date &&
            newSymptomUpdate.time
            //(newSymptomUpdate.isChronic ? newSymptomUpdate.painLevel !== undefined : true)
            //newSymptomUpdate.painLevel &&
            //newSymptomUpdate.isChronic
        ) {
            const data = {
                symptomName: newSymptomUpdate.name,
                description: newSymptomUpdate.description,
                additional: newSymptomUpdate.additional,
                creator: userId,
                date: newSymptomUpdate.date,
                time: newSymptomUpdate.time,
                painLevel: newSymptomUpdate.painLevel,
                isChronic: newSymptomUpdate.isChronic,
            };
            setNewSymptomUpdate({
                name: '',
                description: '',
                additional: '',
                date: null,
                time: '',
                painLevel: 0,
                isChronic: false,
            });
            putSymptom(data);
            setShowModal2(false);
        }
    };

    const putSymptom = async (data) => {
        await fetch(`${API_URLS}/${updateID}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        setRender(!render);
        getSymptoms();
    };

    const addSymptom = () => {
        if (
            !Object.keys(errors).length &&
            newSymptom.name &&
            newSymptom.description &&
            newSymptom.date &&
            newSymptom.time
            //newSymptom.painLevel
            //newSymptom.isChronic
        ) {
            const data = {
                symptomName: newSymptom.name,
                description: newSymptom.description,
                additional: newSymptom.additional,
                creator: userId,
                date: newSymptom.date,
                time: newSymptom.time,
                painLevel: newSymptom.painLevel,
                isChronic: newSymptom.isChronic,
            };
            setNewSymptom({
                name: '',
                description: '',
                additional: '',
                date: null,
                time: '',
                painLevel: 0,
                isChronic: false,
            });
            postSymptom(data);
            setShowModal(false);
        }
    };

    const postSymptom = async (data) => {
        const res = await fetch(API_URLS, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (res.ok) {
            const newSymptom = await res.json();
            getSymptoms();
            return toast.success('Symptom added successfully');
        } else {
            console.error('Failed to post symptom');
            return toast.error('Error occurred while adding symptom');
        }
    };

    const getsymptomDate = (dateString) => {
        const dateObject = new Date(dateString);
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        return dateObject.toLocaleDateString('en-US', options);
    };

    const showDelete = (id) => {
        setDeleteId(id);
        setShowDeleteModal(true);
    };

    const handleDelete = () => {
        confirmDelete();
    };

    const confirmDelete = async () => {
        const id = deleteId;
        setSymptoms((prevSymptoms) =>
            prevSymptoms.filter((symptom) => symptom._id !== id)
        );
        await fetch(`${API_URLS}/${id}`, {
            method: 'DELETE',
        });
        setShowDeleteModal(false);
        return toast.success('Symptom deleted successfully');
    };

    const handleUpdate = (id, name, description, additional, date, time, painLevel, isChronic) => {
        setUpdateID(id);
        const dateObj = new Date(date);
        setNewSymptomUpdate({
            name: name,
            description: description,
            additional: additional,
            date: dateObj,
            time: time,
            painLevel: painLevel,
            isChronic: isChronic,
        });
        setShowModal2(true);
    };

    const isDisabled = (isUpdate = false) => {
        const currentErrors = isUpdate ? errorsUpdate : errors;
        const currentFields = isUpdate ? newSymptomUpdate : newSymptom;
        if (Object.keys(currentErrors).length > 0) return true;
        if (
            !currentFields.name ||
            !currentFields.description ||
            !currentFields.date ||
            !currentFields.time
            //!currentFields.isChronic
        ) {
            return true;
        }
        return false;
    };

    return (
        <Container fluid className="page-container" style={{}}>
            <Row className="d-flex header-row">
                <span className="d-flex flex-row">
                    <h2 className="col-sm-4">Your Recent Symptoms</h2>
                    <Button
                        className="btn btn-md col-sm-3"
                        style={styles}
                        onClick={openModal}
                    >
                        Add +
                    </Button>
                </span>
            </Row>
            <Row style={{ marginTop: '20px', width:'100%' }}>
                {symptoms.map((symptom) => (
                    <div>
                        <Card
                            style={{
                                maxWidth: '100%',
                                transform: 'none',
                                height: 'auto',
                                marginBottom: '10px',
                            }}
                        >
                            <Card.Body>
                                <Row
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <p className="col" style={{
                                        wordWrap: 'break-word',
                                        overflowWrap: 'break-word',
                                        width: '100px'
                                        //maxWidth: '20%'
                                    }}>
                                        {symptom.symptomName
                                            .charAt(0)
                                            .toUpperCase() +
                                            symptom.symptomName.slice(1)}
                                    </p>
                                    <p
                                        className="col"
                                        style={{
                                            fontWeight: 'bold',
                                            display: 'flex',
                                            //marginLeft: '50px'                                         
                                        }}
                                    >
                                        {symptom.date
                                            ? getsymptomDate(symptom.date)
                                            : 'N/A'}{' '}
                                        <span style={{ marginLeft: '100px' }}>
                                            {symptom.time
                                                ? format(
                                                      new Date(
                                                          `2000-01-01T${symptom.time}`
                                                      ),
                                                      'hh:mm a'
                                                  )
                                                : 'N/A'}
                                        </span>
                                    </p>
                                    
                                    <p className="card-text col">
                                        Chronic: {symptom.isChronic ? 'Yes' : 'No'
                                            }
                                    </p>
                                    <p className="card-text col">
                                        Pain Level: {symptom.isChronic ? symptom.painLevel : 'N/A'
                                            }
                                    </p>
                                    <div className="col">
                                        <Button
                                            style={{
                                                marginLeft: '5px',
                                                marginRight: '5px',
                                            }}
                                            className="btn btn-danger btn-md"
                                            onClick={() =>
                                                showDelete(symptom._id)
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faTrash}
                                                size="xs"
                                                style={{ color: '#ffffff' }}
                                            />
                                        </Button>
                                        <Button
                                            style={{ marginLeft: '5px' }}
                                            className="btn btn-md"
                                            onClick={() =>
                                                handleUpdate(
                                                    symptom._id,
                                                    symptom.symptomName,
                                                    symptom.description,
                                                    symptom.additional,
                                                    symptom.date,
                                                    symptom.time,
                                                    symptom.painLevel,
                                                    symptom.isChronic
                                                )
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faPen}
                                                size="xs"
                                                style={{ color: '#ffffff' }}
                                            />
                                        </Button>
                                    </div>
                                </Row>
                                <hr />
                                <Row>
                                    <div
                                        className="col"
                                        style={{ display: 'flex' }}
                                    >
                                        <p className="card-text col" style={{
                                        wordWrap: 'break-word',
                                        overflowWrap: 'break-word',
                                        width: '250px',
                                        //maxWidth: '50%'
                                        }}>
                                        Description: {symptom.description
                                            .charAt(0)
                                            .toUpperCase() +
                                            symptom.description.slice(1)}
                                        </p>

                                        <p className="card-text col" style={{
                                        wordWrap: 'break-word',
                                        overflowWrap: 'break-word',
                                        marginLeft: '100px',
                                        width: '250px',
                                        //maxWidth: '45%'
                                        }}>
                                        Additional Symptoms: {symptom.additional
                                            ? symptom.additional.charAt(0).toUpperCase() + symptom.additional.slice(1)
                                            : 'N/A'}
                                        </p>

                                        
                                        <ul
                                            style={{
                                                listStyle: 'none',
                                                float: 'right',
                                                display: 'flex',
                                            }}
                                        >
                                            {symptom.additionalSymptoms.map(
                                                (symptom, index) => (
                                                    <li
                                                        key={index}
                                                        style={{
                                                            padding: '10px',
                                                            backgroundColor:
                                                                'lightblue',
                                                            marginRight: '5px',
                                                        }}
                                                    >
                                                        {symptom}
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                </Row>
                            </Card.Body>
                        </Card>
                    </div>
                ))}
            </Row>
            {/* <Row style={{ marginTop: '20px' }}>
                <h5>Cold</h5>
                {coldSymptoms.map((symptom) => (
                    <div>
                        <Card
                            style={{
                                maxWidth: '100%',
                                height: '170px',
                                transform: 'none',
                            }}
                        >
                            <Card.Body>
                                <Row
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <p className="col">
                                        {symptom.symptomName
                                            .charAt(0)
                                            .toUpperCase() +
                                            symptom.symptomName.slice(1)}
                                    </p>
                                    <p
                                        className="col"
                                        style={{
                                            fontWeight: 'bold',
                                            display: 'flex',
                                        }}
                                    >
                                        {symptom.date
                                            ? getsymptomDate(symptom.date)
                                            : 'N/A'}{' '}
                                        <span style={{ marginLeft: '200px' }}>
                                            {symptom.time
                                                ? format(
                                                      new Date(
                                                          `2000-01-01T${symptom.time}`
                                                      ),
                                                      'hh:mm a'
                                                  )
                                                : 'N/A'}
                                        </span>
                                    </p>
                                    <p className="card-text col">
                                        {symptom.description
                                            .charAt(0)
                                            .toUpperCase() +
                                            symptom.description.slice(1)}
                                    </p>
                                    <div className="col">
                                        <Button
                                            style={{
                                                marginLeft: '5px',
                                                marginRight: '5px',
                                            }}
                                            className="btn btn-danger btn-md"
                                            onClick={() =>
                                                showDelete(symptom._id)
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faTrash}
                                                size="xs"
                                                style={{ color: '#ffffff' }}
                                            />
                                        </Button>
                                        <Button
                                            style={{ marginLeft: '5px' }}
                                            className="btn btn-md"
                                            onClick={() =>
                                                handleUpdate(
                                                    symptom._id,
                                                    symptom.symptomName,
                                                    symptom.description,
                                                    symptom.date,
                                                    symptom.time
                                                )
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faPen}
                                                size="xs"
                                                style={{ color: '#ffffff' }}
                                            />
                                        </Button>
                                    </div>
                                </Row>
                                <hr />
                                <Row>
                                    <div
                                        className="col"
                                        style={{ display: 'flex' }}
                                    >
                                        <p>Additional Symptoms</p>
                                        <ul
                                            style={{
                                                listStyle: 'none',
                                                float: 'right',
                                                display: 'flex',
                                            }}
                                        >
                                            {symptom.additionalSymptoms.map(
                                                (symptom, index) => (
                                                    <li
                                                        key={index}
                                                        style={{
                                                            padding: '10px',
                                                            backgroundColor:
                                                                'lightblue',
                                                            marginRight: '5px',
                                                        }}
                                                    >
                                                        {symptom}
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                </Row>
                            </Card.Body>
                        </Card>
                    </div>
                ))}
            </Row>
            <Row style={{ marginTop: '20px', width: 'auto'}}>
                <h5>HeadAche</h5>
                {headacheSymptoms.map((symptom) => (
                    <div>
                        <Card
                            style={{
                                maxWidth: '100%',
                                height: '170px',
                                transform: 'none',
                            }}
                        >
                            <Card.Body>
                                <Row
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <p className="col">
                                        {symptom.symptomName
                                            .charAt(0)
                                            .toUpperCase() +
                                            symptom.symptomName.slice(1)}
                                    </p>
                                    <p
                                        className="col"
                                        style={{
                                            fontWeight: 'bold',
                                            display: 'flex',
                                        }}
                                    >
                                        {symptom.date
                                            ? getsymptomDate(symptom.date)
                                            : 'N/A'}{' '}
                                        <span style={{ marginLeft: '200px' }}>
                                            {symptom.time
                                                ? format(
                                                      new Date(
                                                          `2000-01-01T${symptom.time}`
                                                      ),
                                                      'hh:mm a'
                                                  )
                                                : 'N/A'}
                                        </span>
                                    </p>
                                    <p className="card-text col">
                                        {symptom.description
                                            .charAt(0)
                                            .toUpperCase() +
                                            symptom.description.slice(1)}
                                    </p>
                                    <div className="col">
                                        <Button
                                            style={{
                                                marginLeft: '5px',
                                                marginRight: '5px',
                                            }}
                                            className="btn btn-danger btn-md"
                                            onClick={() =>
                                                showDelete(symptom._id)
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faTrash}
                                                size="xs"
                                                style={{ color: '#ffffff' }}
                                            />
                                        </Button>
                                        <Button
                                            style={{ marginLeft: '5px' }}
                                            className="btn btn-md"
                                            onClick={() =>
                                                handleUpdate(
                                                    symptom._id,
                                                    symptom.symptomName,
                                                    symptom.description,
                                                    symptom.date,
                                                    symptom.time
                                                )
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faPen}
                                                size="xs"
                                                style={{ color: '#ffffff' }}
                                            />
                                        </Button>
                                    </div>
                                </Row>
                                <hr />
                                <Row>
                                    <div
                                        className="col"
                                        style={{ display: 'flex' }}
                                    >
                                        <p>Additional Symptoms</p>
                                        <ul
                                            style={{
                                                listStyle: 'none',
                                                float: 'right',
                                                display: 'flex',
                                            }}
                                        >
                                            {symptom.additionalSymptoms.map(
                                                (symptom, index) => (
                                                    <li
                                                        key={index}
                                                        style={{
                                                            padding: '10px',
                                                            backgroundColor:
                                                                'lightblue',
                                                            marginRight: '5px',
                                                        }}
                                                    >
                                                        {symptom}
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                </Row>
                            </Card.Body>
                        </Card>
                    </div>
                ))}
            </Row>
            <Row style={{ marginTop: '20px', width: 'auto' }}>
                <h5>Others</h5>
                {otherSymptoms.map((symptom) => (
                    <div>
                        <Card
                            style={{
                                maxWidth: '100%',
                                height: '170px',
                                transform: 'none',
                            }}
                        >
                            <Card.Body>
                                <Row
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <p className="col">
                                        {symptom.symptomName
                                            .charAt(0)
                                            .toUpperCase() +
                                            symptom.symptomName.slice(1)}
                                    </p>
                                    <p
                                        className="col"
                                        style={{
                                            fontWeight: 'bold',
                                            display: 'flex',
                                        }}
                                    >
                                        {symptom.date
                                            ? getsymptomDate(symptom.date)
                                            : 'N/A'}{' '}
                                        <span style={{ marginLeft: '200px' }}>
                                            {symptom.time
                                                ? format(
                                                      new Date(
                                                          `2000-01-01T${symptom.time}`
                                                      ),
                                                      'hh:mm a'
                                                  )
                                                : 'N/A'}
                                        </span>
                                    </p>
                                    <p className="card-text col">
                                        {symptom.description
                                            .charAt(0)
                                            .toUpperCase() +
                                            symptom.description.slice(1)}
                                    </p>
                                    <div className="col">
                                        <Button
                                            style={{
                                                marginLeft: '5px',
                                                marginRight: '5px',
                                            }}
                                            className="btn btn-danger btn-md"
                                            onClick={() =>
                                                showDelete(symptom._id)
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faTrash}
                                                size="xs"
                                                style={{ color: '#ffffff' }}
                                            />
                                        </Button>
                                        <Button
                                            style={{ marginLeft: '5px' }}
                                            className="btn btn-md"
                                            onClick={() =>
                                                handleUpdate(
                                                    symptom._id,
                                                    symptom.symptomName,
                                                    symptom.description,
                                                    symptom.date,
                                                    symptom.time
                                                )
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faPen}
                                                size="xs"
                                                style={{ color: '#ffffff' }}
                                            />
                                        </Button>
                                    </div>
                                </Row>
                                <hr />
                                <Row>
                                    <div
                                        className="col"
                                        style={{ display: 'flex' }}
                                    >
                                        <p>Additional Symptoms</p>
                                        <ul
                                            style={{
                                                listStyle: 'none',
                                                float: 'right',
                                                display: 'flex',
                                            }}
                                        >
                                            {symptom.additionalSymptoms.map(
                                                (symptom, index) => (
                                                    <li
                                                        key={index}
                                                        style={{
                                                            padding: '10px',
                                                            backgroundColor:
                                                                'lightblue',
                                                            marginRight: '5px',
                                                        }}
                                                    >
                                                        {symptom}
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                </Row>
                            </Card.Body>
                        </Card>
                    </div>
                ))}
            </Row> */}
            <DeleteModal
                show={showDeleteModal}
                onHide={closeDeleteModal}
                onDelete={handleDelete}
            />
            {}
            <Modal show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add a new symptom</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Form>
                        <Form.Group style={{ marginTop: 10 }}>
                            <Form.Label>Symptom</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={newSymptom.name}
                                onChange={handleInputChange}
                                onBlur={(e) =>
                                    validateField(e.target.name, e.target.value)
                                }
                                placeholder="Add symptom name"
                                isInvalid={!!errors.name}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.name}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group style={{ marginTop: 10 }}>
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                type="text"
                                name="description"
                                value={newSymptom.description}
                                onChange={handleInputChange}
                                onBlur={(e) =>
                                    validateField(e.target.name, e.target.value)
                                }
                                placeholder="Describe how you are feeling"
                                isInvalid={!!errors.description}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.description}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group style={{ marginTop: 10 }}>
                            <Form.Label>Additional Symptoms</Form.Label>
                            <Form.Control
                                type="text"
                                name="additional"
                                value={newSymptom.additional}
                                onChange={handleAdditionalChange}
                                // onBlur={(e) =>
                                //     validateField(e.target.name, e.target.value)
                                // }
                                placeholder="Add any additional symptoms"
                                //isInvalid={!!errors.description}
                            />
                            
                        </Form.Group>

                        {/* <Form.Group>
                        <ChipInput></ChipInput>
                        </Form.Group> */}
                        <Form.Group style={{ marginTop: 10 }}>
                            <Form.Label>Date</Form.Label>
                            <br />
                            <div className="customDatePickerWidth">
                                <DatePicker
                                    className={`customDatePickerWidth ${
                                        errors.date ? 'is-invalid' : ''
                                    }`}
                                    selected={newSymptom.date}
                                    onChange={handleDateChange}
                                    onBlur={() =>
                                        validateField('date', newSymptom.date)
                                    }
                                    placeholderText="Select a Date..."
                                    dateFormat="MM/dd/yyyy"
                                />
                                <div className="invalid-feedback">
                                    {errors.date}
                                </div>
                            </div>
                        </Form.Group>
                        <Form.Group style={{ marginTop: 10 }}>
                            <Form.Label>Time</Form.Label>
                            <br />
                            <div className="customDatePickerWidth">
                                <TimePicker
                                    className={`customDatePickerWidth ${
                                        errors.time ? 'is-invalid' : ''
                                    }`}
                                    disableClock="true"
                                    format="hh:mm a"
                                    value={newSymptom.time}
                                    onChange={handleTimeChange}
                                    onBlur={() =>
                                        validateField('time', newSymptom.time)
                                    }
                                />
                                <div className="invalid-feedback">
                                    {errors.time}
                                </div>
                            </div>
                        </Form.Group>
                        <Slider onPainLevelChanged={handlePainLevel}
                                onChronicChanged={handleIsChronic}></Slider>
                        </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={closeModal}>Cancel</Button>
                    <Button onClick={addSymptom} disabled={isDisabled()}>
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showModal2} onHide={closeModal2}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit symptom</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group style={{ marginTop: 10 }}>
                            <Form.Label>Symptom</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={newSymptomUpdate.name}
                                onChange={handleUpdateInputChange}
                                onBlur={(e) =>
                                    validateField(
                                        e.target.name,
                                        e.target.value,
                                        true
                                    )
                                }
                                placeholder="Add symptom name"
                                isInvalid={!!errorsUpdate.name}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errorsUpdate.name}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group style={{ marginTop: 10 }}>
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                type="text"
                                name="description"
                                value={newSymptomUpdate.description}
                                onChange={handleUpdateInputChange}
                                onBlur={(e) =>
                                    validateField(
                                        e.target.name,
                                        e.target.value,
                                        true
                                    )
                                }
                                placeholder="Describe how you are feeling"
                                isInvalid={!!errorsUpdate.description}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errorsUpdate.description}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group style={{ marginTop: 10 }}>
                            <Form.Label>Additional Symptoms</Form.Label>
                            <Form.Control
                                type="text"
                                name="additional"
                                value={newSymptomUpdate.additional}
                                onChange={handleUpdateAdditionalChange}
                                // onBlur={(e) =>
                                //     validateField(e.target.name, e.target.value)
                                // }
                                placeholder="Add any additional symptoms"
                                //isInvalid={!!errors.description}
                            />
                            
                        </Form.Group>

                        {/* <Form.Group style={{ marginTop: 10 }}>
                        <ChipInput></ChipInput>
                        </Form.Group> */}
                        <Form.Group style={{ marginTop: 10 }}>
                            <Form.Label>Date</Form.Label>
                            <br />
                            <div className="customDatePickerWidth">
                                <DatePicker
                                    className={`customDatePickerWidth ${
                                        errorsUpdate.date ? 'is-invalid' : ''
                                    }`}
                                    selected={newSymptomUpdate.date}
                                    onChange={handleUpdateDateChange}
                                    onBlur={() =>
                                        validateField(
                                            'date',
                                            newSymptomUpdate.date,
                                            true
                                        )
                                    }
                                    placeholderText="Select a Date..."
                                    dateFormat="MM/dd/yyyy"
                                />
                                <div className="invalid-feedback">
                                    {errorsUpdate.date}
                                </div>
                            </div>
                        </Form.Group>
                        <Form.Group style={{ marginTop: 10 }}>
                            <Form.Label>Time</Form.Label>
                            <br />
                            <div className="customDatePickerWidth">
                                <TimePicker
                                    className={`customDatePickerWidth ${
                                        errorsUpdate.time ? 'is-invalid' : ''
                                    }`}
                                    disableClock="true"
                                    format="hh:mm a"
                                    value={newSymptomUpdate.time}
                                    onChange={handleUpdateTimeChange}
                                    onBlur={() =>
                                        validateField(
                                            'time',
                                            newSymptomUpdate.time,
                                            true
                                        )
                                    }
                                />
                                <div className="invalid-feedback">
                                    {errorsUpdate.time}
                                </div>
                            </div>
                        </Form.Group>
                        <Slider initialPainLevel={newSymptomUpdate.painLevel}
                                initialIsChronic={newSymptomUpdate.isChronic}
                                onPainLevelChanged={handleUpdatePainLevel}
                                onChronicChanged={handleUpdateIsChronic}
                                ></Slider>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={closeModal2}>Cancel</Button>
                    <Button onClick={updateSymptom} disabled={isDisabled(true)}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>

            <ChatbotSymptoms />
            {(!auth || !auth.user) && <LoginPopup />}
        </Container>
    );
};

export default SymptomTracker;
