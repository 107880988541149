import React, { useState } from 'react';
import { useAuth } from '../providers';
import axios from 'axios';

const FileUpload = (props) => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const auth = useAuth();

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      setError('Please select a file');
      return;
    }

    setUploading(true);
    setError(null);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('userId', auth.user.id);

    try {
      const response = await axios.post('https://medharbor-backend.onrender.com/myrecords', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setUploading(false);
      setUploadSuccess(true);
      props.triggerNextStep({ value: 'File uploaded successfully', trigger: 'medical-records-upload-complete' });
    } catch (error) {
      setUploading(false);
      setError('Error uploading file. Please try again.');
      console.error('Error uploading file:', error);
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} accept="image/*,.pdf" />
      <button onClick={handleUpload} disabled={uploading || uploadSuccess}>
        {uploading ? 'Uploading...' : uploadSuccess ? 'Uploaded' : 'Upload'}
      </button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {uploadSuccess && <p style={{ color: 'green' }}>File uploaded successfully!</p>}
    </div>
  );
};

export default FileUpload;