import React, { useState, useEffect } from 'react';
import ChatBot from 'react-simple-chatbot';

//import '../styles/chatbot.css';
import '../styles/temp.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../providers';
import axios from 'axios';
import FileUpload from './FileUpload'; 
import { set } from 'date-fns';

const Chatbot = () => {
    const [showChat, setShowChat] = useState(false);
    const [userInput, setUserInput] = useState({});
    const [userData, setUserData] = useState({});
    const [newEmail, setNewEmail] = useState('');
    const [newFirstName, setNewFirstName] = useState('');


    let allergyname, allergydesc, allergydate;
    let vaccinename, vaccinedesc, vaccinedate;
    let previousApptDate, previousApptLocation, previousApptDoctor, previousApptComments;
    let upcomingApptDate, upcomingApptLocation, upcomingApptDoctor, upcomingApptPhone, upcomingApptComments;
    let billingdate, billingdesc, billingamt;
    let insurancedate, insuranceprovider, insurancepolicy;

    const auth = useAuth();

    const toggleChat = () => {
        setShowChat(!showChat);
    };

    const closeChat = () => {
        setShowChat(false);
    };

    const SubmitAllergyData = ({ triggerNextStep }) => {
      const submitData = async () => {
        try {
          console.log('submit Allergy Data called with name, desc, date:', allergyname, allergydesc, allergydate); 

          setUserInput(prevState => {
            const newState = {
              ...prevState,
              name: allergyname,
              description: allergydesc,
              date: allergydate
            };
            console.log('Updated userInput:', newState);
    
            sendToBackend(newState, 'allergies'); 
    
            return newState; 
          });
          
        } catch (error) {
          console.error("Error submitting allergy data:", error);
        }
      };
    
      useEffect(() => {
        console.log("inside submit allergy data component");
        submitData();
      }, []); 
    
      return <div>Submitting your data, please wait...</div>;
    };
    
    const SubmitVaccinationData = ({ triggerNextStep }) => {
      const submitData = async () => {
        try {
          console.log('submit Vaccination Data called with name, desc, date', vaccinename, vaccinedesc, vaccinedate); 
          
          setUserInput(prevState => {
            const newState = {
              ...prevState,
              name: vaccinename,
              description: vaccinedesc,
              date: vaccinedate
            };
            console.log('Updated userInput:', newState);
    
            sendToBackend(newState, "vaccinations"); 
    
            return newState; 
          });
          
        } catch (error) {
          console.error("Error submitting vaccine data:", error);
        }
      };
    
      useEffect(() => {
        console.log("inside submit vaccine data component");
        submitData();
      }, []); 
    
      return <div>Submitting your data, please wait...</div>;
    };
    
    const SubmitPrevAppointmentData = ({ triggerNextStep }) => {
      const submitData = async () => {
        try {
          console.log('submit Prev Appointment Data called with date, location, doctor, comments', previousApptDate, previousApptLocation, previousApptDoctor, previousApptComments); 
          
          setUserInput(prevState => {
            const newState = {
              ...prevState,
              date: previousApptDate,
              location: previousApptLocation,
              doctorName: previousApptDoctor,
              comments: previousApptComments
            };
            console.log('Updated userInput:', newState);
    
            sendToBackend(newState, "previousAppointments"); 
    
            return newState; 
          });
          
        } catch (error) {
          console.error("Error submitting vaccine data:", error);
        }
      };
    
      useEffect(() => {
        console.log("inside submit prev appointment data component");
        submitData();
      }, []); 
    
      return <div>Submitting your data, please wait...</div>;
    };

    const SubmitUpcomingAppointmentData = ({ triggerNextStep }) => {
      const submitData = async () => {
        try {
          console.log('submit Upcoming Appointment Data called with date, location, doctor, phone, comments', upcomingApptDate, upcomingApptLocation, upcomingApptDoctor, upcomingApptPhone, upcomingApptComments); 
          // upcomingApptDate, upcomingApptLocation, upcomingApptDoctor, upcomingApptPhone, upcomingApptComments
          setUserInput(prevState => {
            const newState = {
              ...prevState,
              date: upcomingApptDate,
              location: upcomingApptLocation,
              doctorName: upcomingApptDoctor,
              phone: upcomingApptPhone,
              comments: upcomingApptComments
            };
            console.log('Updated userInput:', newState);
    
            sendToBackend(newState, "upcomingAppointments"); 
    
            return newState; 
          });
          
        } catch (error) {
          console.error("Error submitting upcoming appointments data:", error);
        }
      };
    
      useEffect(() => {
        console.log("inside submit upcoming appointment data component");
        submitData();
      }, []); 
    
      return <div>Submitting your data, please wait...</div>;
    };

    const SubmitBillingData = ({ triggerNextStep }) => {
      const submitData = async () => {
        try {
          console.log('submit Billing Data called with date, desc, amount', billingdate, billingdesc, billingamt); 
          // billingdate, billingdesc, billingamt
          setUserInput(prevState => {
            const newState = {
              ...prevState,
              date: billingdate,
              description: billingdesc,
              amount: billingamt
            };
            console.log('Updated userInput:', newState);
    
            sendToBackend(newState, "billingHistory"); 
    
            return newState; 
          });
          
        } catch (error) {
          console.error("Error submitting billing data:", error);
        }
      };
    
      useEffect(() => {
        console.log("inside submit billing data component");
        submitData();
      }, []); 
    
      return <div>Submitting your data, please wait...</div>;
    };

    const SubmitInsuranceData = ({ triggerNextStep }) => {
      const submitData = async () => {
        try {
          console.log('submit Insurance Data called with date, provider, policy', insurancedate, insuranceprovider, insurancepolicy); 
          // insurancedate, insuranceprovider, insurancepolicy
          setUserInput(prevState => {
            const newState = {
              ...prevState,
              validityDate: insurancedate,
              provider: insuranceprovider,
              policyNumber: insurancepolicy
            };
            console.log('Updated userInput:', newState);
    
            sendToBackend(newState, "insurances"); 
    
            return newState; 
          });
          
        } catch (error) {
          console.error("Error submitting insurance data:", error);
        }
      };
    
      useEffect(() => {
        console.log("inside submit insurance data component");
        submitData();
      }, []); 
    
      return <div>Submitting your data, please wait...</div>;
    };

    const sendToBackend = async (userData, endpoint) => {
      try {
        console.log('sending data to backend');
        console.log('data:', userData);
        const response = await axios.post(`https://medharbor-backend.onrender.com/${endpoint}`, {
          ...userData,
          userId: auth.user.id
        });
        console.log('Data sent successfully:', response.data);
        setUserInput({}); 
        return response.data; 
      } catch (error) {
        console.error('Error sending data:', error);
        throw error; 
      }
    };

    

    
    const fetchDataFromBackend = async (endpoint) => {
      try {
        console.log("fetching data from backend");
        const response = await axios.get(`https://medharbor-backend.onrender.com/${endpoint}`);
        setUserData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
      

      const updateUserInfo = async (endpoint, updatedData) => {
  try {
    const response = await axios.patch(`https://medharbor-backend.onrender.com/${endpoint}`, {
      ...updatedData,
      userId: auth.user.id,
    });
    console.log('User data updated successfully:', response.data);
    setUserData(response.data);
  } catch (error) {
    console.error('Error updating data:', error);
  }
};

useEffect(() => {
  //fetchDataFromBackend('userProfile'); 
}, []);

const handleUpdate = () => {
  updateUserInfo('userProfile/update', { email: newEmail, firstName: newFirstName });
};

    const steps = [
        {
          id: 'welcome',
          message: 'Hi, welcome to MedHarbor. What would you like to add today?',
          trigger: 'options',
        },
        {
          id: 'options',
          options: [
            { value: 'allergies', label: 'Allergies', trigger: 'allergies-date' },
            { value: 'vaccinations', label: 'Vaccinations', trigger: 'vaccinations-date' },
            { value: 'previous-appointments', label: 'Previous Appointments', trigger: 'previous-appointments-date' },
            { value: 'upcoming-appointments', label: 'Upcoming Appointments', trigger: 'upcoming-appointments-date' },
            { value: 'billing-records', label: 'Billing Records', trigger: 'billing-records-date' },
            { value: 'insurance-records', label: 'Insurance Records', trigger: 'insurance-records-validity-date' },
            { value: 'medical-records', label: 'Medical Records', trigger: 'medical-records-upload' },
          ],
        },
      
        // Allergies
        {
          id: 'allergies-date',
          message: 'Please enter the date of the allergy (MM/DD/YYYY):',
          trigger: 'allergies-date-input',
        },
        {
          id: 'allergies-date-input',
          user: true,
          trigger: 'allergies-name',
          validator: (value) => {
            const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;
            if (!datePattern.test(value)) {
              return 'Please enter a valid date in MM/DD/YYYY format.';
            }
        
            const [month, day, year] = value.split('/').map(Number);
            const inputDate = new Date(year, month - 1, day);
        
            if (inputDate.getFullYear() !== year || inputDate.getMonth() + 1 !== month || inputDate.getDate() !== day) {
              return 'Invalid date. Please enter a real date in MM/DD/YYYY format.';
            }
        
            const today = new Date();
            if (inputDate > today) {
              return 'The date cannot be in the future. Please enter a valid past date.';
            }
        
            allergydate = value;
        
            return true;
          },
        },
        {
          id: 'allergies-name',
          message: 'What is the name of the allergy?',
          trigger: 'allergies-name-input',
        },
        {
          id: 'allergies-name-input',
          user: true,
          trigger: 'allergies-description',
          validator: (value) => {
            allergyname = value;        
            return true;
          },
        },
        {
          id: 'allergies-description',
          message: 'Please provide a description of the allergy:',
          trigger: 'allergies-description-input',
        },
        {
          id: 'allergies-description-input',
          user: true,
          trigger: 'submit-allergy-data',
          validator: (value) => {
            allergydesc = value;      
            return true;
          },
        },
        {
          id: 'submit-allergy-data',
          component: <SubmitAllergyData />,
          asMessage: true,
          trigger: 'allergies-confirm',
        },
      
        {
          id: 'allergies-confirm',
          message: 'Thank you. Your allergy information has been recorded. Would you like to add anything else?',
          trigger: 'confirm-options',  
      },

       
        {
          id: 'confirm-options',
          options: [
            { value: 'yes', label: 'Yes', trigger: 'options' },
            { value: 'no', label: 'No', trigger: 'end-chat' },
          ],
        },
    
  {
    id: 'vaccinations-date',
    message: 'Please enter the date of the vaccination (MM/DD/YYYY):',
    trigger: 'vaccinations-date-input',
  },
  {
    id: 'vaccinations-date-input',
    user: true,
    trigger: 'vaccinations-name',
    validator: (value) => {
      if (!/^\d{2}\/\d{2}\/\d{4}$/.test(value)) {
        return 'Please enter a valid date in MM/DD/YYYY format.';
      }
      vaccinedate = value;      

      return true;
    },
  },
  {
    id: 'vaccinations-name',
    message: 'What is the name of the vaccination?',
    trigger: 'vaccinations-name-input',
  },
  {
    id: 'vaccinations-name-input',
    user: true,
    trigger: 'vaccinations-description',
    validator: (value) => {
      vaccinename = value;      
      return true;
    },
  },
  {
    id: 'vaccinations-description',
    message: 'Please provide a description of the vaccination:',
    trigger: 'vaccinations-description-input',
  },
  {
    id: 'vaccinations-description-input',
    user: true,
    trigger: 'submit-vaccine-data',
    validator: (value) => {
      vaccinedesc = value;      
      return true;
    },
  },
  {
    id: 'submit-vaccine-data',
    component: <SubmitVaccinationData />,
    asMessage: true,
    trigger: 'vaccinations-confirm',
  },
  {
    id: 'vaccinations-confirm',
    message: 'Thank you. Your vaccination information has been recorded. Would you like to add anything else?',
    trigger: 'confirm-options',
  },

  // Previous Appointments
  {
    id: 'previous-appointments-date',
    message: 'Please enter the date of the previous appointment (MM/DD/YYYY):',
    trigger: 'previous-appointments-date-input',
  },
  {
    id: 'previous-appointments-date-input',
    user: true,
    trigger: 'previous-appointments-location',
    validator: (value) => {
      if (!/^\d{2}\/\d{2}\/\d{4}$/.test(value)) {
        return 'Please enter a valid date in MM/DD/YYYY format.';
      }
      previousApptDate = value;
      return true;
    },
  },
  {
    id: 'previous-appointments-location',
    message: 'Where was the appointment?',
    trigger: 'previous-appointments-location-input',
  },
  {
    id: 'previous-appointments-location-input',
    user: true,
    trigger: 'previous-appointments-doctor',
    validator: (value) => {
      previousApptLocation = value;
      return true;
    }
  },
  {
    id: 'previous-appointments-doctor',
    message: 'What was the name of the doctor?',
    trigger: 'previous-appointments-doctor-input',
  },
  {
    id: 'previous-appointments-doctor-input',
    user: true,
    trigger: 'previous-appointments-comments',
    validator: (value) => {
      previousApptDoctor = value;
      return true;
    }
  },
  {
    id: 'previous-appointments-comments',
    message: 'Any comments about the appointment?',
    trigger: 'previous-appointments-comments-input',
  },
  {
    id: 'previous-appointments-comments-input',
    user: true,
    trigger: 'previous-appointments-submit',
    validator: (value) => {
      previousApptComments = value;
      return true;
    }
  },
  {
    id: 'previous-appointments-submit',
    component: <SubmitPrevAppointmentData />,
    asMessage: true,
    trigger: 'previous-appointments-confirm',
  },
  {
    id: 'previous-appointments-confirm',
    message: 'Thank you. Your previous appointment information has been recorded. Would you like to add anything else?',
    trigger: 'confirm-options',
  },

  // Upcoming Appointments
  {
    id: 'upcoming-appointments-date',
    message: 'Please enter the date of the upcoming appointment (MM/DD/YYYY):',
    trigger: 'upcoming-appointments-date-input',
  },
  {
    id: 'upcoming-appointments-date-input',
    user: true,
    trigger: 'upcoming-appointments-location',
    validator: (value) => {
      if (!/^\d{2}\/\d{2}\/\d{4}$/.test(value)) {
        return 'Please enter a valid date in MM/DD/YYYY format.';
      }
      upcomingApptDate = value;
      return true;
    },
  },
  {
    id: 'upcoming-appointments-location',
    message: 'Where is the appointment?',
    trigger: 'upcoming-appointments-location-input',
  },
  {
    id: 'upcoming-appointments-location-input',
    user: true,
    trigger: 'upcoming-appointments-doctor',
    validator: (value) => {
      upcomingApptLocation = value;
      return true;
    }
  },
  {
    id: 'upcoming-appointments-doctor',
    message: 'What is the name of the doctor?',
    trigger: 'upcoming-appointments-doctor-input',
  },
  {
    id: 'upcoming-appointments-doctor-input',
    user: true,
    trigger: 'upcoming-appointments-phone',
    validator: (value) => {
      upcomingApptDoctor = value;
      return true;
    }
  },
  {
    id: 'upcoming-appointments-phone',
    message: 'What is the phone number for the appointment?',
    trigger: 'upcoming-appointments-phone-input',
  },
  {
    id: 'upcoming-appointments-phone-input',
    user: true,
    trigger: 'upcoming-appointments-comments',
    validator: (value) => {
      upcomingApptPhone = value;
      return true;
    }
  },
  {
    id: 'upcoming-appointments-comments',
    message: 'Any comments about the appointment?',
    trigger: 'upcoming-appointments-comments-input',
  },
  {
    id: 'upcoming-appointments-comments-input',
    user: true,
    trigger: 'upcoming-appointments-submit',
    validator: (value) => {
      upcomingApptComments = value;
      return true;
    }
  },
  {
    id: 'upcoming-appointments-submit',
    component: <SubmitUpcomingAppointmentData />,
    asMessage: true,
    trigger: 'upcoming-appointments-confirm',
  },
  {
    id: 'upcoming-appointments-confirm',
    message: 'Thank you. Your upcoming appointment information has been recorded. Would you like to add anything else?',
    trigger: 'confirm-options',
  },

  // Billing Records
  {
    id: 'billing-records-date',
    message: 'Please enter the date of the billing record (MM/DD/YYYY):',
    trigger: 'billing-records-date-input',
  },
  {
    id: 'billing-records-date-input',
    user: true,
    trigger: 'billing-records-description',
    validator: (value) => {
      if (!/^\d{2}\/\d{2}\/\d{4}$/.test(value)) {
        return 'Please enter a valid date in MM/DD/YYYY format.';
      }
      billingdate = value;
      return true;
    },
  },
  {
    id: 'billing-records-description',
    message: 'Please provide a description of the billing record:',
    trigger: 'billing-records-description-input',
  },
  {
    id: 'billing-records-description-input',
    user: true,
    trigger: 'billing-records-amount',
    validator: (value) => {
      billingdesc = value;      
      return true;
    },
  },
  {
    id: 'billing-records-amount',
    message: 'What is the amount for this billing record?',
    trigger: 'billing-records-amount-input',
  },
  {
    id: 'billing-records-amount-input',
    user: true,
    trigger: 'submit-billing-data',
    validator: (value) => {
      billingamt = value;      
      return true;
    },
  },
  {
    id: 'submit-billing-data',
    component: <SubmitBillingData />,
    asMessage: true,
    trigger: 'billing-records-confirm',
  },
  {
    id: 'billing-records-confirm',
    message: 'Thank you. Your billing record has been recorded. Would you like to add anything else?',
    trigger: 'confirm-options',
  },

  // Insurance Records
  {
    id: 'insurance-records-validity-date',
    message: 'Please enter the validity date of the insurance (MM/DD/YYYY):',
    trigger: 'insurance-records-validity-date-input',
  },
  {
    id: 'insurance-records-validity-date-input',
    user: true,
    trigger: 'insurance-records-provider',
    validator: (value) => {
      if (!/^\d{2}\/\d{2}\/\d{4}$/.test(value)) {
        return 'Please enter a valid date in MM/DD/YYYY format.';
      }
      insurancedate = value;
      return true;
    },
  },
  {
    id: 'insurance-records-provider',
    message: 'Who is the insurance provider?',
    trigger: 'insurance-records-provider-input',
  },
  {
    id: 'insurance-records-provider-input',
    user: true,
    trigger: 'insurance-records-policy-number',
    validator: (value) => {
      insuranceprovider = value;      
      return true;
    },
  },
  {
    id: 'insurance-records-policy-number',
    message: 'What is the policy number?',
    trigger: 'insurance-records-policy-number-input',
  },
  {
    id: 'insurance-records-policy-number-input',
    user: true,
    trigger: 'submit-insurance-data',
    validator: (value) => {
      insurancepolicy = value;      
      return true;
    },
  },
  {
    id: 'submit-insurance-data',
    component: <SubmitInsuranceData />,
    asMessage: true,
    trigger: 'insurance-records-confirm',
  },
  {
    id: 'insurance-records-confirm',
    message: 'Thank you. Your insurance record has been recorded. Would you like to add anything else?',
    trigger: 'confirm-options',
  },

  // Medical Records
  {
    id: 'medical-records-upload',
    //message: 'Please upload your medical record image or file.',
    component: <FileUpload />,
    waitAction: true,
    //trigger: 'medical-records-upload-complete',
  },
  {
    id: 'medical-records-upload-complete',
    message: 'Thank you. Your medical record has been uploaded. Would you like to add anything else?',
    trigger: 'confirm-options',
  },
      
        {
          id: 'confirm-options',
          options: [
            { value: 'yes', label: 'Yes', trigger: 'options' },
            { value: 'no', label: 'No', trigger: 'end-chat' },
          ],
        },
        {
          id: 'end-chat',
          message: 'Thank you for using MedHarbor. Have a great day!',
          end: true,
        },
      ];

    return(
        <div>
            <div className="chat-icon" onClick={toggleChat}>
            <FontAwesomeIcon icon={faCommentDots} size="2x" />
            </div>

            {showChat && (
                <div className="chatbot-container">
                    <ChatBot 
                    headerTitle="My Records Chatbot"
                    speechSynthesis={{ enable: false, lang: 'en' }}
                    steps={steps} />
                </div>
            )}
        </div>
      );
};

export default Chatbot;
