import React, { useState, useEffect } from 'react';
import ChatBot from 'react-simple-chatbot';
//import '../styles/chatbot.css'; // Import the common CSS styles
import '../styles/temp.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios'; // Import axios to send requests to the backend
import { useAuth } from '../providers'; // Assuming you're using a context provider for authentication

const ChatbotMedication = () => {
  const [showChat, setShowChat] = useState(false);
  const [currentMedications, setCurrentMedications] = useState([]);
  const [pastMedications, setPastMedications] = useState([]);
  const auth = useAuth(); // Get authenticated user data
  const backendUrl = "https://medharbor-backend.onrender.com/"; // Update with your backend URL

  // Toggle chat visibility
  const toggleChat = () => {
    setShowChat(!showChat);
  };

  // Fetch medications from backend
  const fetchMedications = async () => {
    try {
      const userId = auth.user?.id;
      if (!userId) {
        console.error('User is not authenticated');
        return;
      }

      const response = await axios.get(`${backendUrl}medication/${userId}`);
      console.log('Medications fetched:', response.data); // Debugging log

      // Separate current and past medications
      const current = response.data.filter((med) => !med.isPastMedicine);
      const past = response.data.filter((med) => med.isPastMedicine);

      setCurrentMedications(current);
      setPastMedications(past);
    } catch (error) {
      console.error('Error fetching medications:', error);
    }
  };

  // Fetch medications when the component mounts
  useEffect(() => {
    fetchMedications();
  }, []);

  // Format date function to show a readable date
  const formatDate = (date) => {
    const newDate = new Date(date);
    return newDate.toLocaleDateString();
  };

  // Define the chatbot steps
  const steps = [
    {
      id: '1',
      message: 'Hello! How can I assist you with your medications today?',
      trigger: '2',
    },
    {
      id: '2',
      message: 'Do you want to know about your current medications or past ones?',
      trigger: '3',
    },
    {
      id: '3',
      options: [
        { value: 'current', label: 'Current medications', trigger: '4' },
        { value: 'past', label: 'Past medications', trigger: '5' },
      ],
    },
    {
      id: '4',
      message: `Here are your current medications: ${
        currentMedications.length > 0
          ? currentMedications
              .map(
                (med) =>
                  `${med.medicationName} (${med.dosage}, ${med.frequency}, Last Filled: ${formatDate(
                    med.lastFilledDate
                  )})`
              )
              .join(', ')
          : 'No current medications available.'
      }`,
      trigger: '6',
    },
    {
      id: '5',
      message: `Here are your past medications: ${
        pastMedications.length > 0
          ? pastMedications
              .map(
                (med) =>
                  `${med.medicationName} (${med.dosage}, ${med.frequency}, Last Filled: ${formatDate(
                    med.lastFilledDate
                  )})`
              )
              .join(', ')
          : 'No past medications available.'
      }`,
      trigger: '6',
    },
    {
      id: '6',
      message: 'Would you like to add a new medication?',
      trigger: '7',
    },
    {
      id: '7',
      options: [
        { value: 'yes', label: 'Yes, add a new medication', trigger: '8' },
        { value: 'no', label: 'No, thank you', trigger: '9' },
      ],
    },
    {
      id: '8',
      message: 'Please enter the details of the medication you would like to add.',
      trigger: '10',
    },
    {
      id: '10',
      user: true, // Allow user to input the medication details
      trigger: '11',
    },
    {
      id: '11',
      message: 'Thank you for your input! The medication has been added.',
      trigger: '12',
    },
    {
      id: '12',
      message: 'Would you like to add another medication?',
      trigger: '7',
    },
    {
      id: '9',
      message: 'Okay, let me know if you need anything else!',
      end: true,
    },
  ];

  return (
    <div>
      {/* Floating Chat Icon */}
      <div className="chat-icon" onClick={toggleChat}>
        <FontAwesomeIcon icon={faCommentDots} size="2x" />
      </div>

      {/* Chatbot Container */}
      {showChat && (
        <div className="chatbot-container">
          <ChatBot
            headerTitle="Medication Chatbot"
            speechSynthesis={{ enable: false, lang: 'en' }}
            steps={steps}
          />
        </div>
      )}
    </div>
  );
};

export default ChatbotMedication;
