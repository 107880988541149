import React, { useState, useEffect } from 'react';
import ChatBot from 'react-simple-chatbot';
import { useAuth } from '../providers';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// '../styles/chatbot.css';
import '../styles/temp.css';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons';

const SymptomsChatbot = () => {
    const [showChat, setShowChat] = useState(false);
    const [symptomData, setSymptomData] = useState({});
    const auth = useAuth();
    const backendUrl = "https://medharbor-backend.onrender.com";
    const userId = auth.user?.id;
    let symptomName,
    symptomDescription,
    additionalSymptoms,
    symptomDate,
    symptomTime,
    isChronic = false,
    painLevel = 0;


    const toggleChat = () => setShowChat(!showChat);
    const SubmitSymptomData = ({ triggerNextStep }) => {
        useEffect(() => {
            const symptomData = {
                symptomName,
                description: symptomDescription,
                additional: additionalSymptoms,
                creator: userId,
                date: symptomDate,
                time: symptomTime,
                painLevel,
                isChronic,
            };
    
            const sendData = async () => {
                try {
                    console.log('Submitting symptom data:', symptomData);
                    const response = await axios.post(`${backendUrl}/symptoms`, symptomData);
                    console.log('Response:', response.data);
                } catch (error) {
                    console.error('Error submitting data:', error);
                }
            };
    
            sendData();
        }, []);
    
        return <div>Submitting your symptom data, please wait...</div>;
    };

    const convertTo24HourFormat = (time) => {
        const date = new Date(`01/01/2000 ${time}`); 
        return date.toTimeString().slice(0, 5);  
    };
   
const steps = [
    {
        id: 'welcome',
        message: 'Welcome to the Symptoms Tracker! What symptom would you like to log?',
        trigger: 'symptomName',
    },
    {
        id: 'symptomName',
        message: 'Enter symptom name (e.g., headache):',
        trigger: 'symptomNameInput',
    },
    {
        id: 'symptomNameInput',
        user: true,
        validator: (value) => {
            if (!value) {
                return 'Please enter a valid symptom name.';
            }
            symptomName = value; 
            return true;
        },
        trigger: 'symptomDescription',
    },
    {
        id: 'symptomDescription',
        message: 'Please provide a brief description of the symptom:',
        trigger: 'symptomDescriptionInput',
    },
    {
        id: 'symptomDescriptionInput',
        user: true,
        validator: (value) => {
            if (!value) {
                return 'Please provide a description.';
            }
            symptomDescription = value; 
            return true;
        },
        trigger: 'symptomAdditional',
    },
    {
        id: 'symptomAdditional',
        message: 'Please provide any additional symptoms:',
        trigger: 'symptomAdditionalInput',
    },
    {
        id: 'symptomAdditionalInput',
        user: true,
        validator: (value) => {
            additionalSymptoms = value;
            return true;
        },
        trigger: 'symptomDate',
    },
    {
        id: 'symptomDate',
        message: 'Please enter the date for your symptom (in MM/DD/YYYY format):',
        trigger: 'symptomDateInput',
    },
    {
        id: 'symptomDateInput',
        user: true,
        validator: (value) => {
            const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;
            if (!datePattern.test(value)) {
                return 'Please enter a valid date in MM/DD/YYYY format.';
            }
            symptomDate = value; 
            return true;
        },
        trigger: 'symptomTime',
    },
    {
        id: 'symptomTime',
        message: 'Please enter the time for your symptom (in HH:MM AM/PM format):',
        trigger: 'symptomTimeInput',
    },
    {
        id: 'symptomTimeInput',
        user: true,
        validator: (value) => {
            const timePattern = /^(0[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/;
            if (!timePattern.test(value)) {
                return 'Please enter a valid time in HH:MM AM/PM format.';
            }
            symptomTime = convertTo24HourFormat(value); 
            return true;
        },
        trigger: 'symptomPain',
    },
    {
        id: 'symptomPain',
        message: 'Is the pain chronic?',
        trigger: 'symptomChronic',
    },
    {
        id: 'symptomChronic',
        options: [
            {
                value: true,
                label: 'Yes',
                trigger: () => {
                    isChronic = true;
                    return 'symptomPainLevel';
                },
            },
            {
                value: false,
                label: 'No',
                trigger: () => {
                    isChronic = false; 
                    return 'submitSymptom';
                },
            },
        ],
    },
    {
        id: 'symptomPainLevel',
        message: 'Please enter the pain level for your symptom (between 1 and 10):',
        trigger: 'symptomPainLevelInput',
    },
    {
        id: 'symptomPainLevelInput',
        user: true,
        validator: (value) => {
            const level = parseInt(value, 10);
            if (isNaN(level) || level < 1 || level > 10) {
                return 'Please enter a valid pain level between 1 and 10.';
            }
            painLevel = level; 
            return true;
        },
        trigger: 'submitSymptom',
    },
    {
        id: 'submitSymptom',
        component: <SubmitSymptomData />,
        asMessage: true,
        trigger: 'confirmation',
    },
    {
        id: 'confirmation',
        message: 'Your symptom has been logged. Do you want to log another?',
        trigger: 'confirmationOptions',
    },
    {
        id: 'confirmationOptions',
        options: [
            { value: 'yes', label: 'Yes', trigger: 'symptomName' },
            { value: 'no', label: 'No', trigger: 'end' },
        ],
    },
    {
        id: 'end',
        message: 'Thank you for using the Symptoms Tracker. Take care!',
        end: true,
    },
];

    return (
        <div>
            <div className="chat-icon" onClick={toggleChat}>
                <FontAwesomeIcon icon={faCommentDots} size="2x" />
            </div>
            {showChat && (
                <div className="chatbot-container">
                    <ChatBot headerTitle="Symptoms Tracker Chatbot" steps={steps} />
                </div>
            )}
        </div>
    );
};

export default SymptomsChatbot;
