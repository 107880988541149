import React, { useState, useRef, useEffect } from 'react';
import '../styles/Signin.css';
import email_icon from '../Assets/email.png';
import password_icon from '../Assets/password.png';
import background from '../Assets/BackgroundPic.png';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useAuth } from '../providers';

const Signin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isResetting, setIsResetting] = useState(false);
    const [isOtpPhase, setIsOtpPhase] = useState(false); // New state for OTP phase
    const [inputOtp, setInputOtp] = useState(['', '', '', '']);
    const inputRefs = [useRef(), useRef(), useRef(), useRef()];
    const [disable, setDisable] = useState(true);
    const [timerCount, setTimer] = useState(30);
    const auth = useAuth();
    const navigate = useNavigate();
    const [generatedOtp, setGeneratedOtp] = useState('');
    const [isNewPasswordPhase, setIsNewPasswordPhase] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const signUserIn = async () => {
        if (!email || !password) {
            return toast.error('Please enter email and password');
        }

        const data = await auth.login(email, password);

        if (!data.success) {
            return toast.error(data.message);
        }

        toast.success('Logged in successfully!');
        navigate('/');
    };

    // Step 1: Enter email for password reset
    const handleNext = async () => {
        if (email) {
            const generate_otp = Math.floor(Math.random() * 9000 + 1000);
            setGeneratedOtp(generate_otp.toString());

            const API_URL = "https://medharbor-backend.onrender.com/resetPassword";
            try {
                const req = await fetch(API_URL, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email, otp: generate_otp }),
                });

                if (req.ok) {
                    setIsOtpPhase(true); // Move to OTP phase
                } else {
                    toast.error("Failed to send OTP. Please try again.");
                }
            } catch (error) {
                console.error(error);
                toast.error("An error occurred. Please try again.");
            }
        } else {
            toast.error("Please enter your email");
        }
    };

    // Step 2: OTP form
    const handleChange = (index, e) => {
        const digit = e.target.value.replace(/\D/, '');
        const newInputOtp = [...inputOtp];
        newInputOtp[index] = digit;
        setInputOtp(newInputOtp);

        // Move to the next input
        if (digit !== '' && index < inputRefs.length - 1) {
            inputRefs[index + 1].current.focus();
        }
    };

    const handleKeyDown = (index, e) => {
        // Move to the previous input on backspace
        if (e.key === 'Backspace' && index > 0 && !inputOtp[index]) {
            inputRefs[index - 1].current.focus();
        }
    };

    const submitOtp = () => {
        if (generatedOtp === inputOtp.join('')) {
            setIsOtpPhase(false); // End OTP phase
            setIsNewPasswordPhase(true);
            //navigate('/newpassword', { state: { email } });
        } else {
            toast.error("Invalid OTP");
        }
    };

    const changePassword = async () => {
        if (!newPassword) {
            toast.error("Password cannot be empty");
        } else if (newPassword !== confirmPassword) {
            toast.error("Passwords do not match");
        } else {
            const data = await auth.update_password(email, newPassword);
            if (!data.success) {
                return toast.error(data.message);
            }
            toast.success("Password updated");
            navigate("/"); // Redirect after successful password change
        }
    };

    const resendOTP = async () => {
        setDisable(true);
        setTimer(30);
        const generate_otp = Math.floor(Math.random() * 9000 + 1000);
        setGeneratedOtp(generate_otp.toString());

        const API_URL = "https://medharbor-backend.onrender.com/resetPassword";
        const req = await fetch(API_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, otp: generate_otp }),
        });

        if (!req.ok) {
            toast.error("Failed to resend OTP. Please try again.");
        }
    };

    useEffect(() => {
        let interval = setInterval(() => {
            setTimer((lastTimerCount) => {
                if (lastTimerCount <= 1) {
                    clearInterval(interval);
                    setDisable(false);
                    return lastTimerCount;
                }
                return lastTimerCount - 1;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [disable]);

    return (
        <div className="signin-container">
            <div className="signin-image-container">
                <img src={background} alt="Healthcare professionals" className="signin-image" />
                <div className="signin-image-overlay"></div>
            </div>

            <div className="signin-form-container">
                <div className="signin-logo">
                    <h2 className="signin-title">{isNewPasswordPhase
                            ? 'Reset Password'
                            : isResetting
                            ? 'Email Verification'
                            : 'Med Harbor Login'}</h2>
                </div>
                {isNewPasswordPhase ? (
                    <>
                        <div className="signin-input-group">
                            <div className="signin-input">
                                <img src={password_icon} alt="Password Icon" style={{ width: '22px' }} />
                                <input
                                    type="password"
                                    placeholder="New Password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    className="signin-input-field"
                                    required
                                />
                            </div>

                            <div className="signin-input">
                                <img src={password_icon} alt="Password Icon" style={{ width: '22px' }} />
                                <input
                                    type="password"
                                    placeholder="Confirm Password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    className="signin-input-field"
                                    required
                                />
                            </div>
                        </div>

                        <div className="signin-submit-container">
                            <button type="button" className="signin-button" onClick={changePassword}>
                                Reset Password
                            </button>
                        </div>
                    </>
                ) : isResetting ? (
                    <>
                        {!isOtpPhase ? (
                            <>
                                <div className="signin-input-group">
                                    <div className="signin-input">
                                        <img src={email_icon} alt="Email Icon" style={{ width: '22px' }} />
                                        <input
                                            type="email"
                                            placeholder="Enter your email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            className="signin-input-field"
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="signin-submit-container">
                                    <button type="button" className="signin-button" onClick={handleNext}>
                                        Next
                                    </button>
                                </div>
                            </>
                        ) : (
                            <>
                                <div style={{ textAlign: 'center', color: 'grey' }}>
                                    Enter the code sent to {email}
                                </div>

                                <div className="signin-input-group" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '20px' }}>
                                    {inputOtp.map((digit, index) => (
                                        <input
                                            key={index}
                                            type="text"
                                            pattern="[0-9]*"
                                            maxLength={1}
                                            value={digit}
                                            onChange={(e) => handleChange(index, e)}
                                            onKeyDown={(e) => handleKeyDown(index, e)}
                                            ref={inputRefs[index]}
                                            className='signin-input-field otp-input'
                                            style={{
                                                width: '40px',
                                                height: '50px',
                                                fontSize: '24px',
                                                textAlign: 'center',
                                                margin: '5px',
                                                backgroundColor: '#f0f0f0', // Light background color
                                                border: '1px solid #ccc', // Border for visibility
                                                borderRadius: '4px', // Rounded corners for a modern look
                                                color: '#333', // Dark text color for better readability
                                                outline: 'none', // Remove default outline
                                            }}
                                        />
                                    ))}
                                </div>

                                <div className="signin-submit-container" style={{ marginTop: 80 }}>
                                    <button type="button" className="signin-button" onClick={submitOtp}>
                                        Verify
                                    </button>
                                </div>

                                <div className="flex flex-row items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500">
                                    <p>Didn't receive code?{" "}
                                        <a
                                            href="#"
                                            style={{
                                                color: disable ? "gray" : "blue",
                                                cursor: disable ? "none" : "pointer",
                                                textDecorationLine: disable ? "none" : "underline",
                                            }}
                                            onClick={resendOTP}
                                        >
                                            {disable ? `Resend OTP in ${timerCount}s` : "Resend OTP"}
                                        </a>
                                    </p>
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <div className="signin-input-group">
                            <div className="signin-input">
                                <img src={email_icon} alt="" style={{ width: '22px' }} />
                                <input
                                    type="email"
                                    placeholder="Email or Med Harbor ID"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="signin-input-field"
                                    required
                                />
                            </div>

                            <div className="signin-input">
                                <img src={password_icon} alt="" style={{ width: '22px' }} />
                                <input
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="signin-input-field"
                                    required
                                />
                            </div>
                        </div>

                        <div className="signin-submit-container">
                            <button type="button" className="signin-button" onClick={signUserIn}>
                                Sign in
                            </button>
                        </div>

                        <div className="signin-links">
                            <Link to="#" className="signin-forgot-password" onClick={() => setIsResetting(true)}>
                                Forgot password?
                            </Link>
                            <div className="signin-no-account">
                                Don't have an account? 
                                <Link to="/signup" className="signin-signup-external-link"> Sign up</Link>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Signin;

